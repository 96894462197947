import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width="26" height="11" viewBox="0 0 26 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24 7C25.1046 7 26 7.89543 26 9V9C26 10.1046 25.1046 11 24 11L2 11C0.89543 11 -1.35705e-07 10.1046 -8.74228e-08 9V9C-3.91405e-08 7.89543 0.895431 7 2 7L24 7Z" fill="#5E6B7E"/>
      <path d="M25.0711 7.24253C25.8522 8.02357 25.8522 9.2899 25.0711 10.071V10.071C24.2901 10.852 23.0238 10.852 22.2427 10.071L16.4143 4.24253C15.6332 3.46148 15.6332 2.19515 16.4143 1.4141V1.4141C17.1953 0.63305 18.4617 0.63305 19.2427 1.4141L25.0711 7.24253Z" fill="#5E6B7E"/>
    </Svg>
  );
};

export default Icon;